/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
.Block {
  margin-bottom: 6rem;
  /*h5{
    line-height: 2rem;
  }*/
  /*
    .video-player-wrapper {
        position: relative;
        padding-top: 56.25%; !* 720 / 1280 = 0.5625 *!
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }*/ }
  @media (max-width: 767.98px) {
    .Block {
      margin-bottom: 0rem; } }
  .Block .img-con {
    padding-top: 56.25%;
    /* 720 / 1280 = 0.5625 */ }
  .Block .back-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }
  .Block .gallery-arrow-left {
    margin-left: -35px; }
    @media (min-width: 768px) {
      .Block .gallery-arrow-left {
        margin-left: -70px; } }
  .Block .gallery-arrow-right {
    margin-right: -35px; }
    @media (min-width: 768px) {
      .Block .gallery-arrow-right {
        margin-right: -70px; } }
  .Block .block-shadow {
    box-shadow: 0px 10px 29px 0px rgba(0, 0, 0, 0.2); }
  @media (max-width: 767.98px) {
    .Block h1 {
      font-size: 2.2rem; } }
