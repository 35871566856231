

@import './color';
@import './bootstrap/variables';
@import "../../node_modules/bootstrap/scss/mixins";

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Inter-Regular.woff2?v=3.11") format("woff2"),
  url("./fonts/Inter-Regular.woff?v=3.11") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  bold;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/Inter-Bold.woff2?v=3.11") format("woff2"),
  url("./fonts/Inter-Bold.woff?v=3.11") format("woff");
}

/*@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("./fonts/Inter-ExtraBold.woff2?v=3.11") format("woff2"),
  url("./fonts/Inter-ExtraBold.woff?v=3.11") format("woff");
}*/



html{


  h1{
    letter-spacing: -0.035em;
    font-size: 3rem;

    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
      //line-height: 1.4rem;
    }

  }

  h4{
    letter-spacing: -0.02em;
  }

  h5{
    letter-spacing: -0.02em;
    font-size: 1.25rem;

    @include media-breakpoint-down(md) {
      font-size: 1.1rem;
      //line-height: 1.4rem;
    }


  }

  p {
    color: #333333;
    letter-spacing: .0em;
    font-size: 1.1rem;
  };

}


/*

  h1{
    //color:$primary;
  }



  html {
    font-size: 1rem;
  }


  @include media-breakpoint-down(md) {

    html {

      font-size: .8rem;

      !*p{
        font-size: .1rem;
      }*!
    }


  }



  @include media-breakpoint-up(xl) {
    html {
      font-size: 1.3rem;

      p{
        font-size: .8rem;
      }

    }
  }
*/
