@import '../color';

//$font-family-base: 'Rubik', sans-serif;


///$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:            $font-family-sans-serif !default;
$font-family-base: 'Inter var', sans-serif; //'Lato', 'sans-serif';


$body-bg: #f7f7f7; //#E4675E; //rgba(248,248,248,1);
$body-color: #1D1D1F; // also font color

$text-muted: #5f5f5f; //$gray-600 !default;


// BTNS
$btn-padding-y:               .7rem;
$btn-padding-x:               1.4rem;
$input-btn-border-width:      2px;
$btn-font-weight:            700;


//$btn-font-weight:             700;

// Grid
$grid-gutter-width: 30px; // Unchanged

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3),
                        6: ($spacer * 4)
                ),
                $spacers
);

// Navbar
$navbar-dark-color:         rgba(#fff, .7);
$navbar-dark-hover-color:   rgba(#fff, .9);
$navbar-nav-link-padding-x: 1rem;

// Modal
$modal-content-bg:     #2a333f;
$modal-backdrop-opacity: .75;
$modal-md:               600px;
$modal-transition:       transform 150ms ease-out;

// Headings
$headings-font-weight: 500;

// Fonts
$font-weight-light:  200;
$font-weight-normal: 400;
$font-weight-bold:   700;
$line-height-base:   1.4;
$font-size-base:     1rem;
$font-size-lg:       $font-size-base * 1.2;
$font-size-sm:       $font-size-base * .8;


// Colors
$theme-colors: (
        'warning': #ffb32c,
        'danger': #ff4422,
        //'primary': #0080ff,
        'primary': $blue,
        'info': #76dcf2
);

// Grid
$grid-breakpoints: ( // Unchanged
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default

$sizes: map-merge(

                (
                        5: 5%,
                        10: 10%,
                        20: 20%,
                        25: 25%,
                        30: 30%,
                        40: 40%,
                        50: 50%,
                        60: 60%,
                        75: 75%,
                        80: 80%,
                        100: 100%,
                        auto: auto
                ),
                $sizes
);
